import React, { useEffect, useState } from 'react';
import mapboxgl from "mapbox-gl";

const GetDirections = ({ map, isMobile, calculateCustomPath, booths, fitMapToBooth, setActiveItem ,ArrowLeftIcon,setShowDirections,destination,setShowCompanyDetails,onBack,setFilter,setFilteredBooths}) => {
  const [startPoint, setStartPoint] = useState('');
  const [endPoint, setEndPoint] = useState((destination || ''));
  const [mode, setMode] = useState('walking');
  const [filteredStartBooths, setFilteredStartBooths] = useState([]);
  const [filteredEndBooths, setFilteredEndBooths] = useState([false]);
  const [showStartDropdown, setShowStartDropdown] = useState(false);
  const [showEndDropdown, setShowEndDropdown] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [isVisible, setIsVisible] = useState(true); 

  const handleModeChange = (newMode) => {
    setMode(newMode);
  };

  const handleCalculatePath = () => {
    if (startPoint && endPoint) {
      const path = calculateCustomPath(startPoint, endPoint, mode);

      const filtered = booths.filter((booth) =>
        [startPoint, endPoint].includes(booth.properties?.Name)
      );

      // Highlight start and end booths
      setFilteredBooths(filtered);
      setFilter(filtered);
  
      // Calculate bounds for the two booths and path
      const bounds = new mapboxgl.LngLatBounds();
  
      // Add start and end booth coordinates to bounds
      booths.forEach((feature) => {
        if (feature.properties?.Name === startPoint || feature.properties?.Name === endPoint) {
          feature.geometry.coordinates[0].forEach((coord) => bounds.extend(coord));
        }
      });
  
      // Add path coordinates to bounds
      path.features.forEach((feature) => {
        feature.geometry.coordinates.forEach((coord) => bounds.extend(coord));
      });
  
      // Fit the map to the bounds without changing the center drastically
      map.fitBounds(bounds, {
        padding: {
          top: isMobile ? 50 : 100, // Padding at the top
          bottom: isMobile ? 50 : 100, // Padding at the bottom
          left: isMobile ? 50 : 500, // Increased padding on the left to shift the view to the right
          right: isMobile ? 50 : 100, // Padding on the right
        },
        maxZoom: 18, // Limit zoom to avoid too much zoom
        duration: 1000, // Smooth animation
        bearing: map.getBearing(), // Keep the current map bearing
        pitch: map.getPitch(), // Keep the current map pitch
      });
  
      // Render the path on the map
      if (map.getLayer('route-arrows')) {
        map.removeLayer('route-arrows');
      }
      
      if (map.getLayer('custom-path')) {
        map.removeLayer('custom-path');
      }
      
      if (map.getSource('custom-path')) {
        map.removeSource('custom-path');
      }
      
      animatePathOnMap(path);
    }
  };
  

  const animatePathOnMap = (path) => {
    if (!path || path.length === 0 || path.features.length === 0) {
      return; // Do nothing if path is not valid
    }

    map.on('styleimagemissing', (event) => {
      const id = event.id;
      if (id === 'arrow-icon') {
         map.loadImage('/right-arrow.png', (error, image) => {
            if (error) {
               console.error('Error loading image:', error);
               return;
            }
            map.addImage(id, image);
         });
      }
   });
    
    if (map.getSource('custom-path')) {
      map.removeSource('custom-path');
    }
    

    // Add new path layer
    map.addSource('custom-path', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [{ type: 'Feature', geometry: { type: 'LineString', coordinates: [] } }],
      },
    });

    map.addLayer({
      id: 'custom-path',
      type: 'line',
      source: 'custom-path',
      layout: { 'line-join': 'round', 'line-cap': 'round'},
      paint: { 'line-color': '#0000FF', 'line-width': 6 },
    });
    
    if (!map.hasImage('arrow-icon')) {
      map.loadImage('/right-arrow.png', (error, image) => {
        if (error) throw error;
        map.addImage('arrow-icon', image);
    
        // Add the arrows layer after ensuring the icon is loaded
        map.addLayer({
          id: 'route-arrows',
          type: 'symbol',
          source: 'custom-path',
          layout: {
            'symbol-placement': 'line',
            'symbol-spacing': ['interpolate', ['linear'], ['zoom'], 5, 0.05, 10, 0.05, 15, 0.05,17,0.05,18,0.01,19,0.01,20,0.01],
            'icon-image': 'arrow-icon',
            'icon-size': 0.07
          },
          paint: {
            // Add transparency if overlapping icons are causing visibility issues
            'icon-opacity': 0.8,
          },
        });
      });
    } else {
      // Add the arrows layer if the image already exists
      map.addLayer({
        id: 'route-arrows',
        type: 'symbol',
        source: 'custom-path',
        layout: {
          'symbol-placement': 'line',
          'symbol-spacing': ['interpolate', ['linear'], ['zoom'], 5, 0.05, 10, 0.05, 15, 0.05,17,0.05,18,0.01,19,0.01,20,0.01],
          'icon-image': 'arrow-icon',
          'icon-size':0.07  
        },
        paint: {
          'icon-opacity': 0.8,
        },
      });
    }
    

    const animatedPath = { type: 'FeatureCollection', features: [{ type: 'Feature', geometry: { type: 'LineString', coordinates: [] } }] };

    let featureIndex = 0;
    let step = 0;

    const animateSegment = () => {
      const currentFeature = path.features[featureIndex];
      const totalSteps = currentFeature.geometry.coordinates.length;

      if (step < totalSteps) {
        animatedPath.features[0].geometry.coordinates.push(currentFeature.geometry.coordinates[step]);
        map.getSource('custom-path').setData(animatedPath);
        step += 1;
        requestAnimationFrame(animateSegment);
      } else if (featureIndex < path.features.length - 1) {
        featureIndex += 1;
        step = 0;
        requestAnimationFrame(animateSegment);
      }
    };

    animateSegment();
  };

  const handleFlip = () => {
    setStartPoint(endPoint);
    setEndPoint(startPoint);
  };

  const handleFilterChange = (value, setFilteredBoothsState, setDropdownState) => {
    if (value) {
      const filtered = booths.filter((feature) =>
        feature.properties?.Name?.toLowerCase().startsWith(value.toLowerCase())
      );
      setFilteredBoothsState(filtered);
      setDropdownState(true);

      // Update filtered booths on the map
      setFilteredBooths(filtered);
      setFilter(filtered);
    } else {
      setFilteredBoothsState([]);
      setDropdownState(false);

      // Reset filters
      setFilteredBooths([]);
      setFilter([]);
    }
  };
  

  const handleStartChange = (e) => {
    const value = e.target.value;
    setStartPoint(value);
    setIsTyping(!!value);
    setStartPoint(value);
    handleFilterChange(value, setFilteredStartBooths, setShowStartDropdown);

  };

  const handleEndChange = (e) => {
    const value = e.target.value;
    setEndPoint(value);
    setIsTyping(!!value);

    setEndPoint(value);
    handleFilterChange(value, setFilteredEndBooths, setShowEndDropdown);
  };

  const handleSelectStart = (booth) => {
    setStartPoint(booth.properties.Name);
    setShowStartDropdown(false);
    setIsTyping(false); 
  };

  const handleSelectEnd = (booth) => {
    setEndPoint(booth.properties.Name);
    setShowEndDropdown(false);
    setIsTyping(false); 
  };

  const truncateDescription = (Description, maxLength = 50) => {
    return Description.length > maxLength ? Description.substring(0, maxLength) + '...' : Description;
  };

  // Remove path on map when user clicks on other elements
  useEffect(() => {
    const handleMapClick = (e) => {
      // Check if the path layer exists, then remove it
      if (map.getLayer('route-arrows')) {
        map.removeLayer('route-arrows');
      }
      
      if (map.getLayer('custom-path')) {
        map.removeLayer('custom-path');
      }
      
      if (map.getSource('custom-path')) {
        map.removeSource('custom-path');
      }
    };
  
    map.on('click', handleMapClick);
  
    return () => {
      map.off('click', handleMapClick); // Cleanup event listener
    };
  }, [map]);

  // Handle Back and Reset buttons
  const handleBack = () => {
   if( setShowDirections && setShowCompanyDetails){
    setShowDirections(false);
    setShowCompanyDetails(true); 
   }
    setStartPoint('');
    setEndPoint('');
    setFilteredStartBooths([]);
    setFilteredEndBooths([]);
    setShowStartDropdown(false);
    setShowEndDropdown(false);
    // setActiveItem("Tasks")

    // Remove path from map if it exists
    if (map.getLayer('route-arrows')) {
      map.removeLayer('route-arrows');
    }
    
    if (map.getLayer('custom-path')) {
      map.removeLayer('custom-path');
    }
    
    if (map.getSource('custom-path')) {
      map.removeSource('custom-path');
    }

    if (map.getLayer('booth-extrusion')) {
      map.removeLayer('booth-extrusion');
     // map.removeSource('booths');
    }

  };

  const handleReset = () => {
    setActiveItem("Search");
    setStartPoint('');
    setEndPoint('');
    setFilteredStartBooths([]);
    setFilteredEndBooths([]);
    setShowStartDropdown(false);
    setShowEndDropdown(false);

    // Remove path from map if it exists
    if (map.getLayer('route-arrows')) {
      map.removeLayer('route-arrows');
    }
    
    if (map.getLayer('custom-path')) {
      map.removeLayer('custom-path');
    }
    
    if (map.getSource('custom-path')) {
      map.removeSource('custom-path');
    }

    if (map.getLayer('booth-extrusion')) {
      map.removeLayer('booth-extrusion');
      //map.removeSource('booths');
    }
    setIsVisible(false); // Hide the container when Reset button is clicked

    const originalCenter = [37.387141132383135, 55.82251563180171]; // Define it locally
    if (map) {
      map.flyTo({
        center: originalCenter,
        essential: true,
        zoom: isMobile ? 17 : 17.75,
        pitch: 30.0,
        bearing: 9.0,
      });
    }
    onBack();
    // setIsVisible(false); // Hide the container when Reset button is clicked
  };

  // if (!isVisible) {
  //   return; // Return nothing if the container is not visible
  // }

  return (
    <div
      onFocus={(e) =>
        e.currentTarget.classList.add("border", "border-blue-500")
      }
      onBlur={(e) =>
        e.currentTarget.classList.remove("border", "border-blue-500")
      }
      className="fixed p-6 w-96 rounded-xl bg-white shadow-lg top-4"
      style={{
        width: `${isMobile ? "calc(100% - 2rem)" : "24rem"}`,
        margin: `${isMobile ? "0 auto" : "0 0 0 4rem"}`,
        left: `${isMobile ? "0" : "unset"}`,
        right: `${isMobile ? "0" : "unset"}`,
        display: `${isVisible ? "block" : "none"}`,
        transition: "height 0.3s ease", // Smooth transition for height change
      }}
    >
      {/* Container */}
      <div className="flex flex-col space-y-0">
        {/* From Section */}
        <div className="flex flex-col w-full items-start space-y-0">
          <div className="w-full flex items-center mb-2">
            <div className="text-blue-600 font-bold text-lg flex flex-col items-start mr-2">
              <label className="block text-gray-500 text-xs">From</label>
              <span className="mt-1">A</span>
            </div>
            <input
              type="text"
              value={startPoint}
              placeholder="Write booth next to you"
              className={`w-full  py-2 px-2 text-gray-700 focus:outline-none placeholder-gray-500 rounded-lg`}
              onChange={handleStartChange}
            />
          </div>
  
          {showStartDropdown && (
            <ul className="w-full bg-white rounded max-h-[350px] overflow-auto mt-1">
              {filteredStartBooths.slice(0, 5).map((booth, index) => (
                <li
                  key={index}
                  onClick={() => handleSelectStart(booth)}
                  className="p-2 custom-dashed cursor-pointer"
                >
                  <div className="flex flex-col">
                    <span className="font-semibold">{booth.properties.Name}</span>
                    <span className="text-gray-500 text-xs">
                      {truncateDescription(booth.properties.Description)}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
  
        {/* Flip Button */}
        <div
          className={`pb-2 relative flex items-center justify-center mb-4 ${
            isTyping ? "opacity-50 pointer-events-none" : ""
          }`}
        >
          <hr
            className={`w-full ${isTyping ? "border-blue-500" : "border-gray-300"}`}
          />
          {!isTyping && (
            <button
              onClick={handleFlip}
              className="absolute bg-white p-1 rounded-full hover:bg-gray-200 transition"
              title="Flip start and end points"
              style={{ top: "-10px" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 text-gray-600"
              >
                <path
                  fillRule="evenodd"
                  d="M6.97 2.47a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06L8.25 4.81V16.5a.75.75 0 0 1-1.5 0V4.81L3.53 8.03a.75.75 0 0 1-1.06-1.06l4.5-4.5Zm9.53 4.28a.75.75 0 0 1 .75.75v11.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 1 1 1.06-1.06l3.22 3.22V7.5a.75.75 0 0 1 .75-.75Z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          )}
        </div>
  
        {/* To Section */}
        <div className="pb-2 flex flex-col w-full items-start space-y-0">
          <div className="w-full flex items-center mb-2">
            <div className="text-blue-600 font-bold text-lg flex flex-col items-start mr-2">
              <label className="block text-gray-500 text-xs">To</label>
              <span className="mt-1">B</span>
            </div>
            <input
              type="text"
              value={endPoint}
              placeholder="Choose destination"
              className={`w-full ml-4 py-2 px-2 text-gray-700 focus:outline-none placeholder-gray-500 rounded-lg`}
              onChange={handleEndChange}
            />
          </div>
          {/* Dropdown section below */}
          {showEndDropdown && (
            <ul className="w-full bg-white rounded max-h-[350px] overflow-auto mt-1">
              {filteredEndBooths.slice(0, 5).map((booth, index) => (
                <li
                  key={index}
                  onClick={() => handleSelectEnd(booth)}
                  className="p-2 custom-dashed cursor-pointer"
                >
                  <div className="flex flex-col">
                    <span className="font-semibold">{booth.properties.Name}</span>
                    <span className="text-gray-500 text-xs">
                      {truncateDescription(booth.properties.Description)}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
  
        {/* Get Directions Button */}
        <button
          onClick={handleCalculatePath}
          disabled={!startPoint || !endPoint}
          className={`w-full py-2 text-white font-semibold rounded-lg 
              ${startPoint && endPoint ? "bg-blue-500 hover:bg-blue-600" : "bg-gray-300 cursor-not-allowed"}`}
        >
          Get Directions
        </button>
      </div>
      
       {/* Button Container */}
      {filteredEndBooths && (
      <div
        className={`${
          isMobile
            ? "fixed left-5 flex space-x-4 transition-all duration-300"
            : "absolute right-[-40px] top-2 flex flex-col space-y-2"
        }`}
        // style={{
        //   bottom: isMobile ? `${isTyping ? 'calc(100vh - 32rem)' : 'calc(100vh - 7rem)'}` : 'unset',
        // }}
      >
        <button
          onClick={handleBack}
          className={`p-2 rounded  ${
            isMobile ? 'pt-10' : ' '} ${
            isMobile ? ' ' : 'bg-gray-100'}`}
          title="Back"
        >
          <ArrowLeftIcon className="w-4 h-4" />
        </button>
        <button
          onClick={handleReset}
          className={`p-2 rounded  ${
            isMobile ? 'pt-10' : ' '} ${
            isMobile ? ' ' : 'bg-gray-100'}`}
          title="Close"
        >
          ✖
        </button>
      </div>
      )}
    </div>
  );
  
  
};

export default GetDirections;
