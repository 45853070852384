
import { useState } from 'react';

const StagesComponent = ({ stagesData,exhibitionData,map,isMobile,isListVieDef = isMobile ? false : true, ArrowLeftIcon}) => {
  const [filteredStages, setFilteredStages] = useState(stagesData.children);
  const [isActiveStage, setIsActiveStage] = useState(true);
  const [isListView, setIsListView] = useState(isListVieDef);

  const toggleListView = () => {
    setIsListView((prev) => !prev);
  };

  // const handleStageClick = (stageName) => {
  //   console.log("Stage clicked:", stageName); // Log to check if click is detected
  
  //   // Find the matching booth in exhibitionData
  //   const matchingFeature = exhibitionData.features.find(
  //     (feature) =>
  //       feature.properties?.name &&
  //       feature.properties.name === stageName
  //   );
  
  //   if (matchingFeature) {
  //     console.log("Matching feature found:", matchingFeature); // Log to verify feature
  //     const [lng, lat] =  matchingFeature.geometry.coordinates[0][0][4];
  
  //     // Log coordinates to verify format
  //     console.log("Coordinates:", lng, lat);

  //    map.setPaintProperty('booths-layer', 'fill-extrusion-height', [
  //         'case',

  //         ['==', ['get', 'id'], matchingFeature.properties.id], // Full height for selected booth
  //         4,
  //         0, // Default height
  //   ]);
  
  //     map.flyTo({ center: [lng, lat], zoom: 19 });
  //   } else {
  //     console.log("No matching feature found for stage:", stageName);
  //   }
  // };
  
   const handleStageHover = (stageName) => {
    // if(isMobile){
    //   setIsActiveStage(false)
    // }

    // Find the matching booth in exhibitionData
    const matchingFeature = exhibitionData.features.find(
      (feature) =>
        feature.properties?.Name &&
        feature.properties.Name === stageName
    );

    if (matchingFeature) {
      console.log("Matching feature found:", matchingFeature); // Log to verify feature
      const [lng, lat] = matchingFeature.geometry.coordinates[0][0][4];

      // Log coordinates to verify format
      console.log("Coordinates:", lng, lat);

      // Adjust the booth's visual properties
      map.setPaintProperty('booths-layer', 'fill-extrusion-height', [
        'case',
        ['==', ['get', 'id'], matchingFeature.properties.id], // Full height for selected booth
        4,
        0, // Default height
      ]);

      // Fly to the booth's location
      map.flyTo({
         center: [lng, lat], 
         zoom: 18.75 ,
         speed: 1, // Lower speed for smoother animation
         curve: 1.5, // Adjust curve for a slower zoom
         easing: (t) => t, // Linear easing for consistent smoothness
      });


    } else {
      console.log("No matching feature found for stage:", stageName);
    }
  };


    return (
      <div >
           <div
            className={`fixed  ${
              isMobile
                ? isListView
                  ? "flex-col"
                  : "bottom-20 flex-row"
                : "top-16 flex-col left-1 pl-4"
            } rounded-lg z-10 size-full`}
            style={{
              marginLeft: `calc((${isMobile ? 0 : 0.65})* (3rem + 1rem))`,
              height: `${isMobile ? (isListView ? 50 : 15.5) : 25}rem`,
              width: `${isMobile ? 100 : 35}%`,
             // overflowX: "hidden",
            }}
          >
          
           {/* Toggle View Button */}
           {isMobile &&(
            <div
              className={`flex ${
                isListView && isMobile ? "justify-start" : "justify-end"
              } items-center p-2`}
              
              style={
                isListView ? {
                position: "sticky", // Sticks to the top when scrolling
                top: 0, // Stick at the top of the scrollable container
                zIndex: 50, // Ensure it stays above other elements
                backgroundColor: "white", // Prevent overlap issues when scrolling
              } : {}}
            >
              <button
                className="bg-blue-100 text-blue-600 px-4 py-2 rounded-lg shadow-md flex items-center space-x-2 border border-blue-400 hover:bg-blue-200"
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "500",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={toggleListView}
              >
                <span style={{ fontSize: "1.2rem", lineHeight: "1" }}>
                  {isListView ? <ArrowLeftIcon className="w-4 h-4" /> : "≡"}
                </span>{" "}
                <span>{isListView ? "Back" : "List View"}</span>
              </button>
            
              {isMobile && isListView && (
               // <span style={{paddingLeft: "10px"}}>Places</span>
                <h3 className="ml-4 text-lg font-semibold">Places</h3>
              )}
            </div>
          )}


          <div
            className={`pl-2 pr-1 custom-scrollbar flex  ${
              isMobile ? isListView ? "flex-col" : "flex-row": "flex-col"
            }`}
            style={{
              overflowY: "auto",
              overflowX: isListView ? "hidden" : "auto",
              maxHeight: isMobile ? "80vh" : "70vh", // Set maximum height for desktop and mobile
              gap:  isMobile ?"0.5rem":" ", // Add space between items
              maxWidth: '100%', 
            }}
          >
            {stagesData.children.map((stage) => (
              <div
                key={stage.id}
                className="flex bg-white rounded-xl shadow-lg overflow-hidden mb-2 flex-none"
                style={{
                  width: `${isMobile ? (isListView ? "99%" : "80vw") : "100%"}`,
                  height: "20"
                }}
                onMouseEnter={() => handleStageHover(stage.name)} // Hover effect added here
              >
                <img
                  src={stage.image}
                  alt={stage.name}
                  className="w-1/3 h-half object-cover"
                />
                <div className="flex flex-col justify-between p-4 w-2/3">
                  <div className="flex justify-between items-start">
                    <h1 className="text-xl font-bold text-gray-900 leading-6 line-clamp-2 mt-2 mb-4 h-15">{stage.name}</h1>
                  </div>
                  
                  <div  className="flex items-center pl-12 px-2 py-2 mt-2">
                  <button className="flex items-center text-blue-500 border border-blue-500 px-5 py-2 rounded-md mr-2 hover:bg-blue-500 hover:text-white">
                    View
                  </button>
                </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      );
      
};

export default StagesComponent;
