import React, { useEffect, useRef, useState } from "react";

const GettingHereTaskBar = ({
  gettinghereTaskBarData,
  gettingHereData,
  map,
  isMobile,
  isListVieDef = isMobile ? false : true,
  ArrowLeftIcon,
  originalBounds,
  onBack,
  setActiveTask,
}) => {
  const [activeGettingHere, setActiveGettingHere] = useState("View All");
  const [isActiveGettingHere, setIsActiveGettingHere] = useState(true);
  const gettinghereBarRef = useRef(null);
  const [isListView, setIsListView] = useState(isListVieDef);
  const [showButtons, setShowButtons] = useState(true);
  
  const toggleListView = () => {
    setIsListView((prev) => !prev);
  };
  
  const gettinghereTaskBarRef = (direction) => {
    if (gettinghereBarRef.current) {
      const scrollAmount = 100;
      if (direction === "left") {
        gettinghereBarRef.current.scrollBy({
          left: -scrollAmount,
          behavior: "smooth",
        });
      } else if (direction === "right") {
        gettinghereBarRef.current.scrollBy({
          left: scrollAmount,
          behavior: "smooth",
        });
      }
    }
  };


  const handleGettingHereTaskClick = (gettinghere) => {
    //setIsActiveGettingHere(true); 
    setActiveGettingHere(gettinghere);
    if (gettinghere === "Parkings") {
      const [lng, lat] = isMobile
        ? [55.28827407830778, 25.22185081625203]
        : [55.28872809134967, 25.221379384594883];
      map.flyTo({
        center: [lng, lat],
        zoom: isMobile ? 16.5 : 16.2,
        speed: 0.65, // Lower speed for smoother animation
        curve: 1.5, // Adjust curve for a slower zoom
        easing: (t) => t,
      });
    } else if (gettinghere === "View All") {
      const [lng, lat] = isMobile
        ? [55.2883731497312, 25.22111679088667]
        : [55.28872809134967, 25.221379384594883];
      map.flyTo({
        center: [lng, lat],
        zoom: isMobile ? 16.5 : 15.75,
        speed: 0.65,
        curve: 1.5,
        easing: (t) => t,
      });
    } else if (gettinghere === "Transportation Hub") {
      const [lng, lat] = isMobile
        ? [55.28868721357725, 25.220129644956117]
        : [55.28895324149394, 25.220423298328043];
      map.flyTo({
        center: [lng, lat],
        zoom: isMobile ? 17.7 : 18.5,
        speed: 0.65,
      });
    }
  };

  // const handleGettingHereClick = (gettinghere) => {
  //    // Find the matching booth in parkingdata
  //    const matchingFeature = parkingdata.features.find(
  //     (feature) =>
  //       feature?.name &&
  //       feature.name === gettinghere
  //   );

  //   if (matchingFeature) {
  //     console.log("Matching feature found:", matchingFeature); // Log to verify feature
  //     const [lng, lat] =  matchingFeature.geometry.coordinates[0];

  //     map.flyTo({ center: [lng, lat], zoom: 17 });
  //   }
  // };

  const handleGettingHereHover = (coordinates) => {
    // Find the matching booth in exhibitionData
    // if (isMobile) {
    //   setIsActiveGettingHere(false);
    // }
    if (coordinates) {
      const [lng, lat] = coordinates;
      isMobile = window.innerWidth <= 768; // Adjust the width as needed for your breakpoint
      map.flyTo({ center: [lng, lat], zoom: isMobile ? 17 : 17.7 });
    }
  };

  // Filtered children based on the selected category
  const filteredChildren =
    activeGettingHere === "View All"
      ? gettingHereData.children.flatMap((child) => child.children)
      : gettingHereData.children.find(
          (category) => category.name === activeGettingHere
        )?.children || [];

  const handleReset = () => {
    if (map && originalBounds) {
      map.fitBounds(originalBounds, {
        essential: true,
        bearing:120,
        pitch: 30.0,
        zoom: 16,
        padding: { top: 20, bottom: 300, left:300, right: 20},
        maxZoom: 25,
      })
    }
    onBack();
    setIsActiveGettingHere(false);
    setShowButtons(false); 
    setActiveTask("View All");
  };

  return (
    <div>
       {showButtons && ( 
      <div
        className={`fixed ${
          isMobile ? "bottom-20" : "top-20 sm:left-16"
        } left-1 z-10 flex space-x-2 overflow-x-auto scrollbar-hide px-1 sm:px-0 inset-x-0 z-[2] max-w-xl`}
        style={{ zIndex: 1000 }}
      >
        {/* Left Scroll Button */}
        {isMobile && (
          <div
            className="shadow-md rounded-full border p-1 cursor-pointer transition duration-200 bg-gradient-to-r from-[#d93953] to-[#f48432] text-white hover:bg-[#d93953] z-4 flex items-center justify-center"
            onClick={() => gettinghereTaskBarRef("left")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              aria-hidden="true"
              className="w-5 h-5 heroicon-stroke-semibold"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              ></path>
            </svg>
          </div>
        )}

        {/* gettinghere Buttons */}
        <div
          className="flex space-x-2 overflow-x-auto scrollbar-hide"
          ref={gettinghereBarRef}
          style={{ maxWidth: "100%", whiteSpace: "nowrap" }}
        >
          {gettinghereTaskBarData.map((gettinghereTask, index) => (
            <button
              key={index}
              onClick={() => handleGettingHereTaskClick(gettinghereTask)}
              className={`inline-flex px-2 py-1 rounded-lg mr-2 text-sm leading-5 items-center whitespace-nowrap font-medium ${
                gettinghereTask === activeGettingHere
                  ? "bg-gradient-to-r from-[#d93953] to-[#f48432] text-white"
                  : "bg-gray-100 text-gray-500 border border-gray-300 hover:bg-gray-200"
              }`}
              style={{
                height: "100%",
                marginLeft: "0.25rem",
                paddingLeft: "1rem",
                paddingRight: "1rem",
              }}
            >
              {gettinghereTask.replace(/"/g, "")}
            </button>
          ))}
        </div>

        {/* Right Scroll Button */}
        {isMobile && (
          <div
            className="shadow-md rounded-full border p-1 cursor-pointer transition duration-200 bg-gradient-to-r from-[#d93953] to-[#f48432] text-white hover:bg-[#d93953] z-4 flex items-center justify-center"
            onClick={() => gettinghereTaskBarRef("right")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              aria-hidden="true"
              className="w-5 h-5 heroicon-stroke-semibold"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              ></path>
            </svg>
          </div>
        )}
      </div>
       )}

      {/* Content Section */}
      {isActiveGettingHere && (
        <div>
        {/* Getting Here Selector */}
          <div
            className={`fixed  ${
              isMobile
                ? isListView
                  ? "flex-col"
                  : "bottom-20 flex-row "
                : "top-32 flex-col pl-5 pr-20 "
            } rounded-lg z-20 size-full`}
            style={{
              marginLeft: `calc((${isMobile ? 0 : 0.65})* (3rem + 1rem))`,
              height: `${isMobile ? (isListView ? 50 : 17) : 25}rem`,
              width: `${isMobile ? "100%" : 35}rem`,
              //overflowX: "hidden",
            }}
          >
          <div className="rounded-lg backdrop-transparent p-0 bg-white/40 !z-50 " >
            
          {/* Toggle View Button */}
          {isMobile &&(
            <div
              className={`flex ${
                isListView && isMobile ? "justify-start" : "justify-end"
              } items-center p-2 `}
              
              style={
                isListView ? {
                position: "sticky", // Sticks to the top when scrolling
                top: 0, // Stick at the top of the scrollable container
                zIndex: 50, // Ensure it stays above other elements
                backgroundColor: "white", // Prevent overlap issues when scrolling
              } : {}}
            >
              <button
                className="bg-blue-100 text-blue-600 px-4 py-2 mb-2 rounded-lg shadow-md flex items-center space-x-2 border border-blue-400 hover:bg-blue-200"
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "500",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={toggleListView}
              >
                <span style={{ fontSize: "1.2rem", lineHeight: "1" }}>
                {isListView ? <ArrowLeftIcon className="w-4 h-4" /> : "≡"}
                </span>{" "}
                <span>{isListView ? "Back" : "List View"}</span>
              </button>
            
              {isMobile && isListView && (
               // <span style={{paddingLeft: "10px"}}>Places</span>
                <h3 className="ml-4 text-lg font-semibold">Places</h3>
              )}
            </div>
          )}

          {/* Child Components */}
          <div
            className={`pl-2 pr-1 custom-scrollbar flex  ${
              isMobile ? isListView ? "flex-col" : "flex-row": "flex-col"
            }`}
            style={{
              overflowY: "auto",
              overflowX: isListView ? "hidden" : "auto",
              width: "98%",// Optional max-width to prevent stretching
              maxHeight: isMobile ? "80vh" : "65vh", // Set maximum height for desktop and mobile
              gap: "0.5rem",
            }}
          >
            {filteredChildren.map((gHereChild) => (
              <div
              key={gHereChild.id}
              className="flex bg-white rounded-xl shadow-lg overflow-hidden ml-2 h-40 flex-none "
              style={{
                width: `${isMobile ? (isListView ? "98%" : "80vw") : "99%"}`
              }}
              onMouseEnter={() =>
                handleGettingHereHover(
                  isMobile ? gHereChild.Mobilecoordinates : gHereChild.Desktopcoordinates
                )
              }
            >
              <img
                src={gHereChild.image}
                alt={gHereChild.name}
                className="w-1/3 h-half object-cover"
              />
              <div className="flex flex-col justify-between p-4 w-2/3">
                <div className="flex justify-between items-start">
                  <h1 className="text-xl font-bold text-gray-900 leading-6 line-clamp-2 mt-2 mb-4 h-15">
                    {gHereChild.name}
                  </h1>
                </div>
                <div className="flex items-center pl-12 px-2 py-2 mt-2">
                  <button className="flex items-center text-blue-500 font-bold leading-6 border border-blue-500 px-5 py-2 rounded-md hover:bg-blue-500 hover:text-white">
                    View
                  </button>
                </div>
              </div>
            </div>
            
            ))}
          </div>
        </div>
        </div>
      </div>
      )}
      {isActiveGettingHere &&(
      <div
        className={`${
          isMobile
            ? "fixed top-60 left-5 flex space-x-4 z-[1000]"
            : "absolute left-[520px] top-32 flex flex-col space-y-2 z-[1000]"
        }`}
        >
        <button
          onClick={handleReset}
          className="p-2 bg-gray-100 rounded hover:bg-gray-200"
          title="Back"
        >
          <ArrowLeftIcon className="w-4 h-4" />
        </button>
        <button
          onClick={handleReset}
          className="p-2 bg-gray-100 rounded hover:bg-gray-200"
          title="Close"
        >
          ✖
        </button>
      </div>
      )}
    </div>
  );
};

export default GettingHereTaskBar;
